import { Col, Form, Input, message, Modal, Row, Upload, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  postService,
  putService,
  writeFileServer,
  updateFileServer,
  getService,
  getDownloadRequest,
} from '../../../service/service';
import { errorCatch } from '../../../tools/Tools';
import validateMessages from '../../../tools/validateMessage';

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export default function RegistrationModal(props) {
  const { EditRow, isModalVisible, isEditMode, projectId, criteriaId } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isData, setIsData] = useState(false);

  const defaultFileList =
    EditRow?.file && isEditMode
      ? [
          {
            uid: '-1',
            name: EditRow.file.fileName,
            status: 'done',
            url: getDownloadRequest(EditRow.file.id),
          },
        ]
      : [];

  function handleUpload(info) {
    setFileList([info.file.originFileObj]);
  }

  useEffect(() => {
    getService(
      `criteriaResultsTwo/get?projectId=${projectId}&criteriaId=${criteriaId}`
    ).then(result => {
      if (result.content.length > 0) {
        setIsData(true);
        form.setFieldsValue({
          ...EditRow,
          initialValue: result.content[0]?.initialValue,
          valueToReach: result.content[0]?.valueToReach,
        });
      } else {
        setIsData(false);
      }
    });

    if (isEditMode) {
      form.setFieldsValue({
        ...EditRow,
      });
    }
  }, []);

  const save = () => {
    form
      .validateFields()
      .then(values => {
        values.project = { id: projectId };
        values.criteria = { id: criteriaId };
        if (isEditMode) {
          if (fileList[0]) {
            const serverApi = EditRow.file
              ? updateFileServer(`file/update/${EditRow.file.id}`, fileList[0])
              : writeFileServer(`file/upload`, fileList[0]);
            serverApi
              .then(response => {
                values.file = { id: response.data.id };
                putService(`criteriaResultsTwo/update/${EditRow.id}`, values)
                  .then(() => {
                    message.success('Амжилттай хадгаллаа');
                    props.close(true);
                  })
                  .catch(error => {
                    errorCatch(error);
                  });
              })
              .catch(error => {
                errorCatch(error);
              });
          } else {
            putService(`criteriaResultsTwo/update/${EditRow.id}`, values)
              .then(() => {
                message.success('Амжилттай хадгаллаа');
                props.close(true);
              })
              .catch(error => {
                errorCatch(error);
              });
          }
        } else if (fileList[0]) {
          writeFileServer(`file/upload`, fileList[0])
            .then(response => {
              values.file = { id: response.data.id };
              postService('criteriaResultsTwo/post', values)
                .then(() => {
                  message.success('Амжилттай хадгаллаа');
                  props.close(true);
                })
                .catch(error => {
                  errorCatch(error);
                });
            })
            .catch(error => {
              errorCatch(error);
            });
        } else {
          postService('criteriaResultsTwo/post', values)
            .then(() => {
              message.success('Амжилттай хадгаллаа');
              props.close(true);
            })
            .catch(error => {
              errorCatch(error);
            });
        }
      })
      .catch(info => {
        errorCatch(info);
      });
  };

  return (
    <div>
      <Modal
        title="Шалгуур үзүүлэлт бүртгэх"
        okText="Хадгалах"
        cancelText="Буцах"
        width={800}
        alignItems="center"
        visible={isModalVisible}
        onOk={save}
        onCancel={() => props.close()}
      >
        <Form
          form={form}
          labelAlign="left"
          layout="vertical"
          name="nest-messages"
          validateMessages={validateMessages}
        >
          <Row gutter={30}>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label={criteriaId === 5 ? 'Сүүний гарц' : 'Амьдын жин'}
                name="initialValue"
              >
                <Input size="large" disabled={!!isData} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label={criteriaId === 5 ? 'Явцын гарц' : 'Явцын жин'}
                name="growth"
                rules={[
                  {
                    required: true,
                    message: 'Заавал бөглөх хэсгийг бөглөнө үү!',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item label="Хүрэх үр дүн" name="valueToReach">
                <Input size="large" disabled={!!isData} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ marginBottom: '30px' }}>
              <Upload
                maxCount={1}
                defaultFileList={[...defaultFileList]}
                customRequest={dummyRequest}
                onChange={handleUpload}
              >
                <Button icon={<UploadOutlined />}>
                  /Хавсралтаар оруулах файлын дээд хэмжээ 15mb байна. Word,
                  excel, PDF байна./
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
