import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Upload,
  InputNumber,
  Radio,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  postService,
  putService,
  updateFileServer,
  writeFileServer,
  getDownloadRequest,
} from '../../../service/service';
import { errorCatch } from '../../../tools/Tools';
import validateMessages from '../../../tools/validateMessage';

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const { TextArea } = Input;

export default function IndirectBenefitModal(props) {
  const { EditRow, isModalVisible, isEditMode, projectId, criteriaId } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const defaultFileList =
    EditRow?.file && isEditMode
      ? [
          {
            uid: '-1',
            name: EditRow.file.fileName,
            status: 'done',
            url: getDownloadRequest(EditRow.file.id),
          },
        ]
      : [];

  function handleUpload(info) {
    setFileList([info.file.originFileObj]);
  }

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        ...EditRow,
      });
    }
  }, []);

  const save = () => {
    form
      .validateFields()
      .then(values => {
        values.project = { id: projectId };
        values.criteria = { id: criteriaId };
        if (isEditMode) {
          if (fileList[0]) {
            const serverApi = EditRow.file
              ? updateFileServer(`file/update/${EditRow.file.id}`, fileList[0])
              : writeFileServer(`file/upload`, fileList[0]);
            serverApi
              .then(response => {
                values.file = { id: response.data.id };
                putService(`indirectBeneficiaries/update/${EditRow.id}`, values)
                  .then(() => {
                    message.success('Амжилттай хадгаллаа');
                    props.close(true);
                  })
                  .catch(error => {
                    errorCatch(error);
                  });
              })
              .catch(error => {
                errorCatch(error);
              });
          } else {
            putService(`indirectBeneficiaries/update/${EditRow.id}`, values)
              .then(() => {
                message.success('Амжилттай хадгаллаа');
                props.close(true);
              })
              .catch(error => {
                errorCatch(error);
              });
          }
        } else if (fileList[0]) {
          writeFileServer(`file/upload`, fileList[0])
            .then(response => {
              values.file = { id: response.data.id };
              postService(`indirectBeneficiaries/post`, values)
                .then(() => {
                  message.success('Амжилттай хадгаллаа');
                  props.close(true);
                })
                .catch(error => {
                  errorCatch(error);
                });
            })
            .catch(error => {
              errorCatch(error);
            });
        } else {
          postService(`indirectBeneficiaries/post`, values)
            .then(() => {
              message.success('Амжилттай хадгаллаа');
              props.close(true);
            })
            .catch(error => {
              errorCatch(error);
            });
        }
      })
      .catch(info => {
        errorCatch(info);
      });
  };

  return (
    <div>
      <Modal
        title="Шууд бус ашиг хүртэгчид бүртгэх"
        okText="Хадгалах"
        cancelText="Буцах"
        width={650}
        alignItems="center"
        visible={isModalVisible}
        onOk={save}
        onCancel={() => props.close()}
      >
        <Form
          form={form}
          labelAlign="left"
          layout="vertical"
          name="nest-messages"
          validateMessages={validateMessages}
        >
          <Row gutter={30}>
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                label="Тоо"
                name="value"
                rules={[
                  {
                    required: true,
                    message: 'Заавал бөглөх хэсгийг бөглөнө үү!',
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}>
              <Form.Item label="Тайлбар" name="description">
                <TextArea size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={24} lg={24}>
              <Form.Item name="isMale">
                <Radio.Group>
                  <Radio value>Эрэгтэй</Radio>
                  <Radio value={false}>Эмэгтэй</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: '30px' }}>
              <Upload
                maxCount={1}
                defaultFileList={[...defaultFileList]}
                customRequest={dummyRequest}
                onChange={handleUpload}
              >
                <Button icon={<UploadOutlined />}>
                  /Хавсралтаар оруулах файлын дээд хэмжээ 15mb байна. Word,
                  excel, PDF байна./
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
