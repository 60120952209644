import styled from 'styled-components';

const ContentWrapper = styled.div`
  padding: 40px;
  font-size: 12px;

  .header {
    margin-right: 15px;
    margin-left: 15px;
  }

  .ant-table {
    font-size: 11px;
  }

  table,
  th,
  td {
    border: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export default ContentWrapper;
