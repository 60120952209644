import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectStore } from '../../../context/ProjectContext';
import { useToolsStore } from '../../../context/Tools';
import { getService } from '../../../service/service';
import { errorCatch } from '../../../tools/Tools';
// import BriefDraft from '../briefdraft';
import ContactFile from '../contanctFile';
import Implementation from '../detailedProject/Implementation';
import Monitoring from '../detailedProject/Monitoring';
import ParticipantsProject from '../detailedProject/ParticipantsProject';
import ProjectInfo from '../detailedProject/ProjectInfo';
import FileUpload from '../fileUpload';
import FileUploadDetailed from '../FileUploadDetailed';
// import Investment from '../investment';
import MainInfo from '../mainInfo';
// import ProjectOrg from '../projectOrganizations';
import ProjectPerformanceFile from '../projectPerformaceFile';
import ContentWrapper from './projectInfo.style';
import TrainingList from '../ProjectTrainingList';
import Registration from '../detailedProject/Registration';
import IndirectBenefit from '../detailedProject/IndirectBenefit';
import Service from '../detailedProject/Service';
import Sales from '../detailedProject/Sales';

const { TabPane } = Tabs;
const tabPosition = 'top';
const tabPosition2 = 'top';
const tabPosition3 = 'left';

export default function projectInfo() {
  const { id } = useParams();
  const { ProjectList, setProjectList } = useProjectStore();
  const [CriteriaList, setCriteriaList] = useState();
  const { setIsShowLoader } = useToolsStore();
  const toolsStore = useToolsStore();

  useEffect(() => {
    setIsShowLoader(true);
    getService(`/project/get/${id}`)
      .then(result => {
        setProjectList(result);
      })
      .finally(setIsShowLoader(false))
      .catch(error => {
        errorCatch(error);
        setIsShowLoader(false);
      });

    getService(`/projectCriteria/getCriteriaListByProjectId/${id}`)
      .then(result => {
        setCriteriaList(result);
      })
      .finally(setIsShowLoader(false))
      .catch(error => {
        errorCatch(error);
        setIsShowLoader(false);
      });
  }, []);

  return (
    <ContentWrapper>
      <h1
        style={{
          marginLeft: '45px',
          marginRight: '45px',
          color: '#103154',
          fontWeight: '600',
          textAlign: 'right',
        }}
      >
        {ProjectList?.code} - {ProjectList?.organization?.name}
      </h1>
      <Tabs tabPosition={tabPosition2}>
        <TabPane tab="Төслийн мэдээлэл" key="1">
          {/* <Tabs tabPosition={tabPosition}>
            <TabPane tab="Үндсэн мэдээлэл" key="2">
              <MainInfo projectId={id} />
            </TabPane>
            <TabPane tab="Товч төсөл" key="3">
              <BriefDraft projectId={id} />
            </TabPane>
            <TabPane tab="Хөрөнгө оруулалт" key="4">
              <Investment projectId={id} />
            </TabPane>
            <TabPane tab="Түншлэгч байгууллага" key="5">
              <ProjectOrg projectId={id} />
            </TabPane>
          </Tabs> */}
          <MainInfo projectId={id} />
        </TabPane>

        <TabPane tab="Хураангуй төсөл" key="12">
          <FileUpload projectId={id} />
        </TabPane>

        {toolsStore.user.role.id === 21 || toolsStore.user.role.id === 19 ? (
          ''
        ) : (
          <TabPane tab="Дэлгэрэнгүй төсөл" key="14">
            <FileUploadDetailed projectId={id} />
          </TabPane>
        )}

        <TabPane tab="Гэрээ" key="17">
          <ContactFile projectId={id} />
        </TabPane>

        <TabPane tab="Шалгуур үзүүлэлт" key="16">
          <Tabs tabPosition={tabPosition3} tabBarStyle={{ width: '20%' }}>
            {CriteriaList?.map(z => (
              <TabPane tab={z.name} key={z.name}>
                {z.code === 14 ? (
                  <ParticipantsProject projectId={id} criteriaId={14} />
                ) : (
                  ''
                )}
                {z.code === 19 ? (
                  <ParticipantsProject projectId={id} criteriaId={19} />
                ) : (
                  ''
                )}
                {z.code === 5 ? (
                  <Registration projectId={id} criteriaId={5} />
                ) : (
                  ''
                )}
                {z.code === 3 ? (
                  <Registration projectId={id} criteriaId={3} />
                ) : (
                  ''
                )}
                {z.code === 4 ? (
                  <Registration projectId={id} criteriaId={4} />
                ) : (
                  ''
                )}
                {/* {z.code === 21 ? (
                  <IndirectBenefit projectId={id} criteriaId={21} />
                ) : (
                  ''
                )} */}
                {z.code === 20 ? <Sales projectId={id} criteriaId={20} /> : ''}
              </TabPane>
            ))}
            <TabPane tab="Шууд бус ашиг хүртэгчид" key="200">
              <IndirectBenefit projectId={id} criteriaId={21} />
            </TabPane>
            <TabPane tab="Үйлчилгээ">
              <Service projectId={id} />
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane tab="Төслийн гүйцэтгэл" key="7">
          <Tabs tabPosition={tabPosition}>
            <TabPane tab="Гэрээгээр хүлээсэн үүрэг" key="8">
              <ProjectInfo />
            </TabPane>
            <TabPane
              tab="Байгаль орчин нийгмийн менежментийн төлөвлөгөө"
              key="9"
            >
              <Implementation />
            </TabPane>
            <TabPane tab="Байгууллагын санхүүгийн мэдээлэл" key="10">
              Хөгжүүлэлт хийгдэж байна...
            </TabPane>
            <TabPane tab="Хөндлөнгийн хяналт-шинжилгээ, үнэлгээ" key="11">
              <Monitoring />
            </TabPane>
            <TabPane tab="Хавсралт файл" key="15">
              {/* <Rating /> */}
              <ProjectPerformanceFile projectId={id} />
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane tab="Сургалт" key="18">
          {/* <ContactFile projectId={id} /> */}
          <TrainingList projectId={id} />
        </TabPane>
      </Tabs>
    </ContentWrapper>
  );
}
