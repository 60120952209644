import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/mn_MN';
import moment from 'moment';
import 'moment/locale/mn';
import React, { useEffect, useState } from 'react';
import {
  getService,
  postService,
  putService,
  updateFileServer,
  writeFileServer,
} from '../../../../service/service';
import { errorCatch } from '../../../../tools/Tools';
import validateMessages from '../../../../tools/validateMessage';
import ContentWrapper from '../style/ProjectInfoModal.style';

const { TextArea } = Input;
const { Option } = Select;

export default function MonitoringModal(props) {
  const { EditRow, isModalVisible, isEditMode, projectId } = props;
  const [form] = Form.useForm();
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const [fileList] = useState([]);
  const [operationList, setOperationList] = useState([]);

  function startDate(date, value) {
    setStartDateValue(value);
  }

  function endDate(date, value) {
    setEndDateValue(value);
  }

  useEffect(() => {
    getService(`operation/getListByProjectId/${projectId}`).then(result => {
      if (result) {
        setOperationList(result || []);
      }
    });
    if (isEditMode) {
      setStartDateValue(EditRow.startDate);
      setEndDateValue(EditRow.endDate);
      form.setFieldsValue({
        ...EditRow,
      });
    }
  }, []);

  const save = () => {
    form
      .validateFields()
      .then(values => {
        values.externalMonitoring = {
          startDate: startDateValue,
          endDate: endDateValue,
          description: values.description,
          report: values.report,
          operation: { id: values.operationId },
          shouldBeGrantedMoney: values.shouldBeGrantedMoney,
        };
        values.projectId = projectId;
        if (isEditMode) {
          if (fileList[0]) {
            const serverApi = EditRow.file
              ? updateFileServer(`file/update/${EditRow.file.id}`, fileList[0])
              : writeFileServer(`file/upload`, fileList[0]);
            serverApi
              .then(response => {
                values.fileId = response.data.id;
                putService(`externalMonitoring/update/${EditRow.id}`, values)
                  .then(() => {
                    message.success('Амжилттай хадгаллаа');
                    props.close(true);
                  })
                  .catch(error => {
                    errorCatch(error);
                  });
              })
              .catch(error => {
                errorCatch(error);
              });
          } else {
            putService(`externalMonitoring/update/${EditRow.id}`, values)
              .then(() => {
                message.success('Амжилттай хадгаллаа');
                props.close(true);
              })
              .catch(error => {
                errorCatch(error);
              });
          }
        } else if (fileList[0]) {
          writeFileServer(`file/upload`, fileList[0])
            .then(response => {
              values.fileId = response.data.id;
              postService('externalMonitoring/post', values)
                .then(() => {
                  message.success('Амжилттай хадгаллаа');
                  props.close(true);
                })
                .catch(error => {
                  errorCatch(error);
                });
            })
            .catch(error => {
              errorCatch(error);
            });
        } else {
          postService('externalMonitoring/post', values)
            .then(() => {
              message.success('Амжилттай хадгаллаа');
              props.close(true);
            })
            .catch(error => {
              errorCatch(error);
            });
        }
      })
      .catch(info => {
        errorCatch(info);
      });
  };

  return (
    <div>
      <Modal
        title="Хөндлөнгийн хяналт-шинжилгээ, үнэлгээ"
        okText="Хадгалах"
        cancelText="Буцах"
        width={1100}
        alignItems="center"
        visible={isModalVisible}
        onOk={save}
        onCancel={() => props.close()}
      >
        <ContentWrapper>
          <Form
            form={form}
            labelAlign="left"
            layout="vertical"
            name="nest-messages"
            validateMessages={validateMessages}
          >
            <Row gutter={30}>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Үйл ажиллагаа:" name="operationId">
                  {isEditMode ? (
                    <Select
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      maxTagCount="responsive"
                      placeholder="Үйл ажиллагаа сонгох"
                      size="small"
                      defaultValue={EditRow.operation.id}
                    >
                      {operationList &&
                        operationList.map((z, index) => (
                          <Option key={index} value={z.id}>
                            <Tooltip placement="topLeft" title={z.plannedWork}>
                              {z.plannedWork}
                            </Tooltip>
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      maxTagCount="responsive"
                      placeholder="Үйл ажиллагаа сонгох"
                      size="small"
                    >
                      {operationList &&
                        operationList.map((z, index) => (
                          <Option key={index} value={z.id}>
                            <Tooltip placement="topLeft" title={z.plannedWork}>
                              {z.plannedWork}
                            </Tooltip>
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Эхлэх хугацаа:">
                  <DatePicker
                    placeholder="Огноо сонгох"
                    locale={locale}
                    onChange={startDate}
                    defaultValue={
                      isEditMode ? moment(EditRow.startDate).zone(0) : ''
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Дуусах хугацаа:">
                  <DatePicker
                    placeholder="Огноо сонгох"
                    locale={locale}
                    onChange={endDate}
                    defaultValue={
                      isEditMode ? moment(EditRow.endDate).zone(0) : ''
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Олгох дүн:" name="shouldBeGrantedMoney">
                  <InputNumber
                    style={{ width: '50%' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Тайлбар:" name="description">
                  <TextArea rows={6} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Тайлан:" name="report">
                  <TextArea rows={6} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ContentWrapper>
      </Modal>
    </div>
  );
}
