/* eslint-disable no-nested-ternary */
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  DatePicker,
  Radio,
  Select,
  InputNumber,
  Button,
  Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/mn_MN';
import {
  postService,
  putService,
  getService,
} from '../../../../service/service';
import { errorCatch } from '../../../../tools/Tools';
import validateMessages from '../../../../tools/validateMessage';
import ContentWrapper from '../style/ProjectInfoModal.style';
import 'moment/locale/mn';
import { ToolsContext } from '../../../../context/Tools';

const { TextArea } = Input;
const { Option } = Select;

export default function ProjectInfoModal(props) {
  const { EditRow, isModalVisible, isEditMode, projectId } = props;
  const [form] = Form.useForm();
  const [isDelayValue, setIsDelayValue] = useState();
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const [stateAimag, setStateAimag] = useState([]);
  const toolsStore = useContext(ToolsContext);

  const isDelayFunc = e => {
    setIsDelayValue(e.target.value);
  };

  function startDate(date, value) {
    setStartDateValue(value);
  }

  function endDate(date, value) {
    setEndDateValue(value);
  }

  useEffect(() => {
    getService(`project/getProjectsAimags/${projectId}`).then(result => {
      if (result) {
        setStateAimag(result || []);
      }
    });
    if (isEditMode) {
      setIsDelayValue(EditRow.isDelay);
      setStartDateValue(EditRow.startDate);
      setEndDateValue(EditRow.endDate);
      form.setFieldsValue({
        ...EditRow,
      });
    }
  }, []);

  const save = () => {
    form
      .validateFields()
      .then(values => {
        values.operation = {
          startDate: startDateValue,
          endDate: endDateValue,
          plannedWork: values.plannedWork,
          performanceProcess: values.performanceProcess,
          reasonOfDelay: values.reasonOfDelay,
          isDelay: isDelayValue,
          description: values.description,
          plannedBudget: values.plannedBudget,
        };
        values.projectId = projectId;

        if (isEditMode) {
          putService(`operation/update/${EditRow.id}`, values)
            .then(() => {
              message.success('Амжилттай хадгаллаа');
              props.close(true);
            })
            .catch(error => {
              errorCatch(error);
            });
        } else {
          postService(`operation/post`, values)
            .then(() => {
              message.success('Амжилттай хадгаллаа');
              props.close(true);
            })
            .catch(error => {
              errorCatch(error);
            });
        }
      })
      .catch(info => {
        errorCatch(info);
      });
  };

  return (
    <div>
      <Modal
        title="Үйл ажиллагаа бүртгэх"
        okText="Хадгалах"
        cancelText="Буцах"
        width={1200}
        alignItems="center"
        visible={isModalVisible}
        onOk={save}
        onCancel={() => props.close()}
        footer={[
          toolsStore.user.role.id === 19 ||
          toolsStore.user.role.id === 31 ? null : (
            <>
              <Button key="back" onClick={() => props.close()}>
                Буцах
              </Button>
              <Button key="submit" type="primary" onClick={save}>
                Хадгалах
              </Button>
            </>
          ),
        ]}
      >
        <ContentWrapper>
          <Form
            form={form}
            labelAlign="left"
            layout="vertical"
            name="nest-messages"
            validateMessages={validateMessages}
          >
            <Row gutter={30}>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Эхлэх хугацаа:">
                  <DatePicker
                    placeholder="Огноо сонгох"
                    locale={locale}
                    onChange={startDate}
                    defaultValue={
                      isEditMode ? moment(EditRow.startDate).zone(0) : ''
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Дуусах хугацаа:">
                  <DatePicker
                    placeholder="Огноо сонгох"
                    locale={locale}
                    onChange={endDate}
                    defaultValue={
                      isEditMode ? moment(EditRow.endDate).zone(0) : ''
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8}>
                <Form.Item label="Хоцрогдол:" name="isDelay">
                  <Radio.Group onChange={isDelayFunc} value={isDelayValue}>
                    <Radio value>Хоцорсон</Radio>
                    <Radio value={false}>Хоцроогүй</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Төлөвлөсөн ажил:" name="plannedWork">
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Гүйцэтгэлийн явц:" name="performanceProcess">
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item label="Төлөвлөсөн:" name="plannedBudget">
                  <InputNumber
                    style={{ width: '50%' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </Col>
              {isDelayValue === true ? (
                <Col xs={24} md={24} lg={12}>
                  <Form.Item label="Хоцрогдлын шалтгаан:" name="reasonOfDelay">
                    <TextArea rows={5} />
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>

            <Row>
              <Form.List name="operationAimagList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Col xs={24} md={24} lg={24}>
                          <Form.Item {...restField} name={[name, 'aimagId']}>
                            <Select
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              maxTagCount="responsive"
                              placeholder="Аймаг сонгох"
                              size="small"
                            >
                              {stateAimag &&
                                stateAimag.map((z, index) => (
                                  <Option key={index} value={z.id}>
                                    {z.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, 'budget']}
                            fieldKey={[fieldKey, 'bugdet']}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              placeholder="Төлөвлөсөн дүн"
                            />
                          </Form.Item>
                        </Col>

                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ marginRight: '7px' }}
                        />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Аймаг нэмэх
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Row>
          </Form>
        </ContentWrapper>
      </Modal>
    </div>
  );
}
