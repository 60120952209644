import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToolsContext } from '../../context/Tools';
import { getService } from '../../service/service';
import { errorCatch } from '../../tools/Tools';
import ContentWrapper from './style';

const PlanExportt = () => {
  const [list, setList] = useState();
  const loadLazyTimeout = null;
  const toolsStore = useContext(ToolsContext);
  const [lazyParams] = useState({
    page: 0,
  });
  const PAGESIZE = 20;
  const location = useLocation();

  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  const onInit = () => {
    toolsStore.setIsShowLoader(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    getService(`planActivity/getForPlan${location.search}`)
      .then(result => {
        const listResult = result || [];
        listResult.forEach((item, index) => {
          item.index = lazyParams.page * PAGESIZE + index + 1;
        });
        setList(listResult);
      })
      .finally(toolsStore.setIsShowLoader(false))
      .catch(error => {
        errorCatch(error);
        toolsStore.setIsShowLoader(false);
      });
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ContentWrapper>
      <div className="planexporttt">
        <div style={{ display: 'flex' }}>
          <p>Батлав: ______________________</p>
          <p>
            Т.Жамбалцэрэн <br />
            Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамны <br />
            Төрийн нарийн бичгийн дарга <br />
            (Төслийн удирдах хороог төлөөлж)
          </p>
        </div>
        <h2>
          <b>
            МОНГОЛ УЛС: ХӨДӨӨ АЖ АХУЙН ЭДИЙН ЗАСГИЙН ЭРГЭЛТИЙГ НЭМЭГДҮҮЛЭХ ТӨСӨЛ
          </b>
        </h2>
        <h3 style={{ marginTop: '-13px' }}>
          <b>ҮЙЛ АЖИЛЛАГААНЫ ТӨЛӨВЛӨГӨӨ</b>
        </h3>
        <p style={{ marginTop: '30px', marginBottom: '30px' }}>
          <b>
            Хамрах хугацаа: {getQueryVariable('year')} оны 1 дүгээр сарын 1 –{' '}
            {getQueryVariable('year')} оны 12 дугаар сарын 31
          </b>
        </p>
        <p>Шинэчилсэн огноо: .......... оны ..... дүгээр сарын …..</p>
        <p style={{ marginTop: '-13px' }}>
          Баталсан огноо: .......... оны ..... дүгээр сарын …..
        </p>
        <table style={{ width: '100%' }}>
          <tr>
            <th rowSpan="2">Үйл ажиллагаа</th>
            <th rowSpan="2">Үйл ажиллагааны дэс дараалал, задаргаа</th>
            <th colSpan="4">{getQueryVariable('year')}</th>
            <th rowSpan="2">
              Төслийн хөгжлийн зорилт, дунд хугацааны шалгуур үзүүлэлтэд
              хамаарах үр дүн
            </th>
            <th rowSpan="2">Тайлбар</th>
          </tr>
          <tr>
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
          </tr>

          {list?.map(z => (
            <>
              <tr style={{ backgroundColor: '#808080', color: 'white' }}>
                <td colSpan="8">{z.compositionName}</td>
              </tr>
              {z.subCompositionForDTOList?.map(d => (
                <>
                  <tr style={{ backgroundColor: '#D5DCE4', color: 'black' }}>
                    <td colSpan="8">{d?.subCompositionName}</td>
                  </tr>

                  {d.planForDTOList.map(plan =>
                    plan.planActivityDTOList.map((k, index) => (
                      <tr>
                        {index === 0 && (
                          <td rowSpan={plan.planActivityDTOList.length}>
                            {plan.planName}
                          </td>
                        )}
                        <td>{k.planActivityName}</td>
                        <td
                          style={{
                            background: k.q1 ? '#B4C6E7' : '',
                            width: '50px',
                            heigth: '50px',
                          }}
                        />
                        <td
                          style={{
                            background: k.q2 ? '#B4C6E7' : '',
                            width: '50px',
                            heigth: '50px',
                          }}
                        />
                        <td
                          style={{
                            background: k.q3 ? '#B4C6E7' : '',
                            width: '50px',
                            heigth: '50px',
                          }}
                        />
                        <td
                          style={{
                            background: k.q4 ? '#B4C6E7' : '',
                            width: '50px',
                            heigth: '50px',
                          }}
                        />
                        {index === 0 && (
                          <td rowSpan={plan.planActivityDTOList.length}>
                            {plan.planTarget === 'null ' ? '' : plan.planTarget}
                          </td>
                        )}
                        <td>{plan.planDescription}</td>
                      </tr>
                    ))
                  )}
                </>
              ))}
            </>
          ))}
        </table>
      </div>
    </ContentWrapper>
  );
};

export default PlanExportt;
